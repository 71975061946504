<template>
  <div class="search_content_sty" :class="[{ input_sty: isInput }]">
    <input
      type="text"
      :placeholder="placeholderText"
      @blur="isInput = false"
      @focus="isInput = true"
      v-model="searchText"
      @keyup.enter="search"
    />
    <i class="iconfont search_icon_sty" @mousedown.stop="search">&#xe611;</i>
  </div>
</template>

<script>
export default {
  data() {
    return {
        isInput: false,
        searchText:''
    };
  },
  props:{
      placeholderText:{
          type:String,
          default:'试卷名'
      }
  },
  methods:{
      search() {
          this.$emit('search',this.searchText)
      }
  }
};
</script>

<style lang="less" scoped>
.search_content_sty {
  display: inline-block;
  width: 239px;
  height: 34px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 17px;
  position: relative;
  & > input {
    width: 100%;
    height: 100%;
    padding-left: 17px;
    font-size: 14px;
    width: 180px;
    overflow: hidden;
    &::placeholder {
      color: #999;
      font-size: 14px;
    }
  }
  & > .search_icon_sty {
    position: absolute;
    right: 16px;
    top: 10px;
    color: #999;
    cursor: pointer;
  }
}
.input_sty {
  border: 1px solid #0d79ff !important;
}
</style>