<template>
  <div
    class="test_item_content_sty"
    :class="testStyle"
  >
    <div class="test_item_content_status_sty">
      <div>考试</div>
      <span class="status_sty" :class="testStyle">
        {{ status }}
      </span>
    </div>
    <div class="test_item_content_title_sty">{{ test.title }}</div>
    <div class="test_item_content_time_sty">
      <span v-if="notStarted">
        剩余:<em> {{ surplusTime(time, test.endTime) }} </em>
      </span>
      <span v-else>
        开考时间为：{{formatDate(test.startTime)}}
      </span>
    </div>
    <div class="test_btn_sty" @click.stop="goTest" v-if="startBtn && !isTimeOutInto && notStarted">
      立即考试
    </div>
    <div class="continue_btn_sty" @click.stop="goContinue" v-if="continueBtn">
      继续考试
    </div>
    <div class="test_restart_btn_sty" @click.stop="goReexamination" v-if="restartBtn">
      重考
    </div>
    <div class="test_un_btn_sty" v-if="!startBtn && !restartBtn && !continueBtn">
      {{ test.viewTestPaper ? "考试已结束" : "不允许查看" }}
    </div>
    <div class="test_un_btn_sty" v-if="isTimeOutInto && startBtn">
      考试已超过入场时间
    </div>
  </div>
</template>

<script>
import { surplusTime, formatDate } from "@/utils/index";
import getCurrentTime from "@/mixins/getCurrentTime.js";
export default {
  data() {
    return {
      surplusTime,
      formatDate
    };
  },
  mixins:[getCurrentTime],
  computed: {
    // 是否已超出入场时间
    isTimeOutInto: function() {
      // 获取已过去时间(分钟)
      let timePassed = (new Date(this.currentTime) - new Date(this.test.startTime))/60000;
      if( this.test.startMaxTime != 0 && timePassed > this.test.startMaxTime) {
        return true
      }else {
        return false
      }
    },
    time: function () {
      return this.$store.state.currentTime;
    },
    // 试卷状态
    status: function () {
      if (this.test.status == 0) {
        return "未完成";
      } else if (this.test.status == 1) {
        return "考试中";
      } else if (this.test.status == 2) {
        return "已完成";
      } else {
        return "未交卷";
      }
    },
    // 试卷状态色
    testStyle: function() {
      if (this.test.status == 0) {
        return "";
      } else if (this.test.status == 1) {
        return "examing_sty";
      } else if (this.test.status == 2) {
        return "complete_sty";
      } else {
        return "incomplete_sty";
      }
    },
    // 是否显示重考按钮
    restartBtn: function () {
      // redo:{1为可重考，0为不可重考}
      if (
        this.test.redo == 1 &&
        this.test.endTime > this.time &&
        this.test.redoMaxCount - this.test.testCount >= 0 &&
        (this.test.status == 2 || this.test.status == 3) 
      ) {
        return true;
      } else {
        return false;
      }
    },
    // 是否显示考试按钮
    startBtn: function () {
      if (this.test.status == 0 && this.test.endTime > this.time) {
        return true;
      } else {
        return false;
      }
    },
    // 显示继续考试时间
    continueBtn: function () {
      if (this.test.status == 1 && this.test.endTime > this.time) {
        return true;
      } else {
        return false;
      }
    },
    // 未到考试时间
    notStarted: function() {
      if(this.test.startTime < this.time) {
        return true
      }else {
        return false
      }
    }
  },
  props: {
    test: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {

  },
  methods: {
    goTest() {
      this.$emit("goTest", this.test.testPaperId);
    },
    goReexamination(){
      this.$emit("goReexamination", this.test.testPaperId);
    },
    goContinue() {
      this.$router.push(`/myStudy?option=3&path=examination&testId=${this.test.testPaperId}`)
    }
  },
};
</script>

<style lang="less" scoped>
.test_item_content_sty {
  width: 208px;
  height: 250px;
  background: #ffffff;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  border-top: 3px solid #ff6262;
  position: relative;
  display: inline-block;
  .test_item_content_status_sty {
    width: 100%;
    position: relative;
    height: 20px;
    margin-top: 13px;
    & > div {
      &:first-child {
        display: inline-block;
        width: 44px;
        vertical-align: top;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background: #b8c4d2;
        font-size: 12px;
        color: #fff;
        margin: 0 16px 0 16px;
      }
    }
    .status_sty {
      position: absolute;
      height: 20px;
      line-height: 20px;
      right: 16px;
      top: 0;
      color: #ff3b3b;
    }
  }
  .test_item_content_title_sty {
    padding: 0 15px;
    box-sizing: border-box;
    font-size: 18px;
    height: 38px;
    line-height: 20px;
    font-weight: 500;
    color: #333333;
    margin-top: 25px;
  }
  .test_item_content_time_sty {
    padding: 0 16px;
    padding-right: 0;
    box-sizing: border-box;
    margin-top: 10px;
    & > span {
      color: #bfbfbf;
      font-size: 12px;
      & > em {
        font-style: normal;
        color: #888888;
      }
    }
  }
  .test_btn_sty {
    position: absolute;
    bottom: 18px;
    width: 113px;
    height: 36px;
    line-height: 36px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    border: 1px solid #ededed;
    border-radius: 40px;
    cursor: pointer;
    color: #ff6262;
    &:hover {
      background: #FF6262;
      color: #fff;
    }
  }
  .continue_btn_sty {
    position: absolute;
    bottom: 18px;
    width: 113px;
    height: 36px;
    line-height: 36px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    border: 1px solid #ededed;
    border-radius: 40px;
    cursor: pointer;
    color: #FF850D;
    &:hover {
      background: #FF850D;
      color: #fff;
    }
  }
  .test_restart_btn_sty {
    position: absolute;
    bottom: 18px;
    width: 113px;
    height: 36px;
    line-height: 36px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    border: 1px solid #ededed;
    border-radius: 40px;
    cursor: pointer;
    color: #0d79ff;
    &:hover {
      background: #0D79FF;
      color: #fff;
    }
  }
  .test_un_btn_sty {
    position: absolute;
    bottom: 18px;
    width: 143px;
    height: 36px;
    line-height: 36px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    // border: 1px solid #ededed;
    border-radius: 40px;
    cursor: pointer;
    color: #adadad;
  }
}
.complete_sty {
  border-color: #0d79ff !important;
  color: #0d79ff !important;
}
.incomplete_sty {
  border-color: #b8c4d2 !important;
  color: #adadad !important;
}
.examing_sty {
  border-color: #FF850D !important;
  color: #FF850D !important;
}
</style>