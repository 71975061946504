import http from "../utils/http"
const baseUrl = '/app/study'

/**
 * 获取我的班级
 * @param 
 * method:get
 * path:'/app/study/class'
*/

export const myClass = () => http.get(`${baseUrl}/class`)

/**
 * 学员/课程所属班级列表
 * @param (classId, params)
 * method:get
 * path:'/app/study/courses/{courseId}/classes'
*/

export const myCourseClass = courseId => http.get(`${baseUrl}/courses/${courseId}/classes`)

/**
 * 获取班内课程列表
 * @param (classId, params)
 * method:get
 * path:'/app/study/class/{classId}/course'
*/

export const myClassCourse = (params,classId) => http.get(`${baseUrl}/class/${classId}/course`, {params})

/**
 * 根据邀请码获取班级详情
 * @param inviteCode
 * method:get
 * path:'/app/study/class/inviteCode/{inviteCode}'
*/

export const getClassInfo = inviteCode => http.get(`${baseUrl}/class/inviteCode/${inviteCode}`)


/**
 * 申请加入班级
 * @param classId
 * method:post
 * path:'/app/study/class/join/{classId}'
*/

export const joinClass = classId => http.post(`${baseUrl}/class/join/${classId}`)