<template>
  <div class="test_content_sty">
    <title class="test_title_sty">我的考试</title>
    <div class="test_filter_content_sty">
      <span class="laber_sty">状态:</span>
      <el-radio v-model="status" label="2">全部</el-radio>
      <el-radio v-model="status" label="0">未完成</el-radio>
      <el-radio v-model="status" label="1">已结束</el-radio>
      <el-select v-model="params.classId" placeholder="请选择" size="small">
        <el-option
          v-for="item in classList"
          :key="item.classId"
          :label="item.className"
          :value="item.classId"
        >
        </el-option>
      </el-select>
      <div class="search_sty">
        <searchInput @search="search" />
      </div>
    </div>
    <div class="test_content_list_sty">
      <testItem
        v-for="i in testList"
        :key="i.testPaperId"
        class="test_content_item_sty"
        :test="i"
        @goTest="goTest"
        @goReexamination="goReexamination"
        @click.native="seeTestPaper(i)"
      />
      <empty-status v-show="testList.length == 0" />
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="500px">
      <test-tip @close="close" :testPaperContent="testPaperContent" />
    </el-dialog>
    <el-dialog title="提示" :visible.sync="reDialogVisible" width="500px">
      <reexamination-tip @close="close" :testPaperContent="testPaperContent" />
    </el-dialog>
    <div class="turn_page_sty">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="params.pageNum"
        :page-size="params.pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
        hide-on-single-page
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import searchInput from "../searchInput";
import testItem from "../testItem";
import { getTestInfo, getPcTestList } from "@/api/test";
import { myClass } from "@/api/class";
import testTip from "../testTip";
import { mapActions } from "vuex";
import emptyStatus from "../emptyStatus";
import reexaminationTip from "../testTip/reexaminationTip";
export default {
  components: {
    searchInput,
    testItem,
    testTip,
    emptyStatus,
    reexaminationTip,
  },
  data() {
    return {
      radio: "1",
      testList: [],
      params: {
        classId: null,
        status: null,
        title: "",
        pageSize: 8,
        pageNum: 1,
      },
      total: 0,
      dialogVisible: false,
      reDialogVisible: false,
      status: "2",
      classList: [],
      testPaperContent: {},
    };
  },
  created() {
    this.getPcTestList();
    this.getCurrentTime();
    this.myClass();
  },
  watch: {
    status: function () {
      this.params.pageNum = 1;
      if (this.status == 2) {
        this.params.status = null;
      } else {
        this.params.status = this.status;
      }
      this.getPcTestList();
    },
    "params.classId": function () {
      this.params.pageNum = 1;
      this.getPcTestList();
    },
  },
  computed: {
    now: function () {
      return this.$store.state.currentTime;
    },
  },
  methods: {
    getPcTestList() {
      let that = this;
      this.$loading.show();
      getPcTestList(this.params).then((json) => {
        that.$loading.hide()
        const { code, msg, rows, total } = json;
        if (code === 200) {
          this.testList = rows;
          this.total = total;
        } else {
          this.$message({
            type: "warning",
            message: msg,
          });
        }
      });
    },
    // 关闭tip
    close() {
      this.dialogVisible = false;
      this.reDialogVisible = false;
    },
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.getPcTestList();
    },
    // 搜索
    search(val) {
      if (val.trim() == "") {
        this.params.title = "";
        this.params.pageNum = 1;
        this.getPcTestList();
      } else {
        this.params.pageNum = 1;
        this.params.title = val;
        this.getPcTestList();
      }
    },
    // 获取我的班级
    myClass() {
      myClass().then((json) => {
        const { code, rows, msg } = json;
        if (code === 200) {
          this.classList = [{ classId: null, className: "全部课程" }, ...rows];
        } else {
          this.$message({
            type: "error",
            message: msg,
          });
        }
      });
    },
    // 获取试卷信息
    getTestInfo(id) {
      getTestInfo(id).then((json) => {
        const { code, data, msg } = json;
        if (code === 200) {
          this.testPaperContent = data;
        } else {
          this.$message.error(msg);
        }
      });
    },
    // 去考试
    goTest(val) {
      this.dialogVisible = true;
      this.getTestInfo(val);
    },
    // 去重考
    goReexamination(val) {
      this.reDialogVisible = true;
      this.getTestInfo(val);
    },
    // 查看答题
    seeTestPaper(item) {
      if (item.viewTestPaper == 1 && item.status) {
        this.$router.push({
          path: "/myStudy?option=3&path=viewExamination",
          query: { id: item.testPaperId },
        });
      } else {
        return false;
      }
    },
    // 获取当前时间
    ...mapActions(["getCurrentTime"]),
  },
};
</script>

<style lang="less" scoped>
.test_content_sty {
  width: 100%;
  text-align: left;
  height: 100%;
  min-height: 748px;
  position: relative;
  .test_title_sty {
    height: 60px;
    line-height: 60px;
    display: block;
    border-bottom: 1px solid #eeeeee;
    padding-left: 29px;
    box-sizing: border-box;
    color: #333;
    font-size: 18px;
    font-weight: 500;
  }
  .test_filter_content_sty {
    margin-top: 20px;
    position: relative;
    height: 34px;
    line-height: 34px;
    .search_sty {
      position: absolute;
      right: 32px;
      top: 0;
      line-height: 1;
    }
  }
  .test_content_list_sty {
    padding-left: 30px;
    box-sizing: border-box;
    min-height: 573px;
    .test_content_item_sty {
      margin-right: 24px;
      margin-top: 24px;
      cursor: pointer;
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
  .laber_sty {
    margin-right: 19px;
    margin-left: 32px;
  }
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #f2f2f2;
}

.test_filter_content_sty  /deep/ .el-input__inner {
  border-radius: 40px !important;
}

.turn_page_sty {
  text-align: right;
  position: absolute;
  bottom: 26px;
  right: 30px;
  // margin-top: 20px;
}
</style>