<template>
  <div class="empty">
    <img :src="img" alt="" />
    <p>{{ desc }}</p>
    <button v-show="haveButton" class="btn" @click="handleClick">
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    // 空状态图片
    img: {
      type: String,
      default: require("@/assets/img/emptyIcon/wusoshou@2x.png"),
    },
    // 描述文字
    desc: {
      type: String,
      default: "什么都没有找到呢~",
    },
    // 是否有按钮
    haveButton: {
      type: Boolean,
      default: false,
    },
    // 按钮文字
    buttonText: {
      type: String,
      default: "去学习",
    },
  },
  methods: {
    // 抛出按钮事件
    handleClick() {
      this.$emit('btnClick')
    }
  }
};
</script>

<style lang="less" scoped>
.empty {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto !important;
  min-height: auto !important;
  transform: translate(-50%, -50%);
  text-align: center;
  p {
    margin-top: 20px;
    font-size: 14px;
    color: #999;
    text-align: center;
  }
  .btn {
    margin-top: 18px;
    width: 134px;
    height: 40px;
    font-size: 14px;
    color: #fff;
    background: #0d79ff;
    border-radius: 20px;
  }
}
</style>